import * as client_hooks from '../../src/hooks.client.ts';

export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70')];

export const server_loads = [];

export const dictionary = {
	"/": [14],
	"/(no-auth)/access-denied": [69,[13]],
	"/(app)/data-converter/[port]": [15,[2]],
	"/(no-auth)/debugger": [70,[13]],
	"/(auth)/login": [67,[12]],
	"/(auth)/login/callback": [68,[12]],
	"/(app)/namespaces": [16,[2]],
	"/(app)/namespaces/create": [37,[2]],
	"/(app)/namespaces/[namespace]": [17,[2]],
	"/(app)/namespaces/[namespace]/edit": [35,[2]],
	"/(app)/namespaces/[namespace]/(core)/schedules": [18,[2,3,4]],
	"/(app)/namespaces/[namespace]/(core)/schedules/create": [21,[2,3,4]],
	"/(app)/namespaces/[namespace]/(core)/schedules/[schedule]": [19,[2,3,4]],
	"/(app)/namespaces/[namespace]/(core)/schedules/[schedule]/edit": [20,[2,3,4]],
	"/(app)/namespaces/[namespace]/(core)/task-queues": [22,[2,3,5]],
	"/(app)/namespaces/[namespace]/(core)/task-queues/[queue]": [23,[2,3,5]],
	"/(app)/namespaces/[namespace]/usage": [36,[2]],
	"/(app)/namespaces/[namespace]/(core)/workflows": [24,[2,3]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]": [25,[2,3,6]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history": [26,[2,3,6]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/compact": [27,[2,3,6]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/feed": [28,[2,3,6]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/json": [29,[2,3,6]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/pending-activities": [30,[2,3,6]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/query": [31,[2,3,6]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/stack-trace": [32,[2,3,6]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/v2": [33,[2,3]],
	"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/workers": [34,[2,3,6]],
	"/(app)/settings": [38,[2,7]],
	"/(app)/settings/integrations": [39,[2,7]],
	"/(app)/settings/integrations/audit-logging": [40,[2,7]],
	"/(app)/settings/integrations/observability": [41,[2,7]],
	"/(app)/settings/users": [42,[2,7]],
	"/(app)/settings/users/create": [45,[2,7]],
	"/(app)/settings/users/[user]": [43,[2,7]],
	"/(app)/settings/users/[user]/edit": [44,[2,7]],
	"/(app)/support": [~46,[2,8]],
	"/(app)/support/accounts": [47,[2,8,9]],
	"/(app)/support/accounts/create": [49,[2,8,9]],
	"/(app)/support/accounts/[accountid]": [48,[2,8,9]],
	"/(app)/support/cells": [50,[2,8,10]],
	"/(app)/support/cells/deployments": [53,[2,8,10]],
	"/(app)/support/cells/deploy": [52,[2,8,10]],
	"/(app)/support/cells/[cellid]": [51,[2,8,10]],
	"/(app)/support/exports": [54,[2,8,11]],
	"/(app)/support/exports/users": [55,[2,8,11]],
	"/(app)/support/exports/zendesk/organizations": [56,[2,8,11]],
	"/(app)/support/exports/zendesk/users": [57,[2,8,11]],
	"/(app)/support/grant": [58,[2,8]],
	"/(app)/support/namespaces": [59,[2,8]],
	"/(app)/support/namespaces/[namespaceid]": [60,[2,8]],
	"/(app)/support/settings": [61,[2,8]],
	"/(app)/support/users": [62,[2,8]],
	"/(app)/support/users/[userid]": [63,[2,8]],
	"/(app)/usage": [64,[2]],
	"/(app)/usage/[namespace]": [65,[2]],
	"/(app)/welcome": [66,[2]]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};